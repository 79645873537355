<template>
  <div class="user-credits" v-if="$profile && $user && $locale">
    <div class="user-credits-content">
      <div class="user-credits-cover">
        <UserCreditsCard />
      </div>
      <div class="user-credits-card">
        <p class="title TitleCreditsPackages" strong>{{ $locale["reload_credits"] }}</p>
        <div v-if="$loading" vpadding strong><iconic name="gspinner" dcolor /></div>
        <div v-else class="CreditsPackagesAvailables">
          <UserCreditsSuggest v-for="(item, index) in items" :item="item" :key="index" @checked="numCheck" />
        </div>
      </div>
    </div>
    <Spacer num="1" />

    <router-view />
  </div>
</template>

<script>
import HamburgerBtn from "../home/HamburgerBtn.vue";
import UserCreditsBtn from "./UserCreditsBtn.vue";
import UserCreditsCard from "../user/UserCreditsCard.vue";
import ViewHeader from "../view/ViewHeader.vue";
import UserCreditsSuggest from "./UserCreditsSuggest.vue";

export default {
  components: { ViewHeader, HamburgerBtn, UserCreditsBtn, UserCreditsCard, UserCreditsSuggest },
  data: function() {
    return {
      profile: null,
      mount: null,
      items: null,
      item: null,
    };
  },
  methods: {
    numCheck: function(data) {
      this.item = data;
      this.mount = data && data.price;
      this.$store.dispatch("payload", { method: "append", itemToReload: data });
    },
    getData: async function() {
      this.isLoading(true);
      await this.$store.dispatch("getProfile", (profile) => {
        this.profile = profile;
      });

      try {
        const req = await this.$api.get(process.env.VUE_APP_API_HOST + "products?type=credits");
        this.items = req.data.items;
      } catch (error) {
        this.ialert.go({ title: "Error", message: error.message });
      }

      this.isLoading(false);
    },
  },
  mounted: function() {
    this.getData();
  },
};
</script>

<style lang="scss">
.user-credits {
  &-cover {
    @include BgImage(var(--texture), $primary_color);
    position: relative;
    min-height: 140px;
    border-radius: 0 0 $mpadding $mpadding;
    padding: $mpadding * 2;
  }
  &-card {
    margin: -$mpadding 0 0 0;
    padding: $mpadding * 2 $mpadding * 1.5 $mpadding $mpadding * 1.5;
    box-shadow: 2px 2px 6px rgba(68, 68, 68, 0.3);
    border-radius: 0px 0px $mpadding $mpadding;
    background: var(--card-bg);
    .title {
      font-weight: bold;
      border-bottom: solid 1px $alto;
      padding: 0 0 $mpadding 0;
    }
    .credits-suugest {
      margin: $mpadding 0 0 0;
    }
    .options {
      text-align: center;
      margin: $mpadding * 3 0 $mpadding 0;
      .reload-button {
        display: inline-block;
        padding: $mpadding/1.5 $mpadding;
        @include PrimaryButtonDisabled();
        pointer-events: none;
        &.isReady {
          @include PrimaryButton();
          pointer-events: all;
        }
      }
    }
  }

  .TitleCreditsPackages {
    position: sticky;
    top: var(--header-height);
    background-color: var(--theme-background);
    z-index: 1;
    padding: $mpadding 0;
  }

  @media (min-width: $tablet_width) {
    &-cover {
      padding: $mpadding * 3;
    }
    &-content {
      max-width: $pocket_width;
      margin: $mpadding auto;
    }
    &-cover {
      border-radius: $mpadding;
    }
  }
}
</style>
